import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import getEnv from "utils/getEnv";
import isPreviewMode from "utils/isPreviewMode";

datadogLogs.init({
  clientToken: getEnv("datadog_client_token"),
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  sampleRate: getEnv("datadog_sample_rate"),
  useSecureSessionCookie: true,
});

datadogRum.init({
  applicationId: getEnv("datadog_rum_app_id"),
  clientToken: getEnv("datadog_client_token"),
  site: "datadoghq.com",
  service: "patient-check-in",
  env: getEnv("datadog_rum_env"),
  version: getEnv("datadog_rum_app_version"),
  sessionSampleRate: getEnv("datadog_rum_session_sample_rate"),
  sessionReplaySampleRate: getEnv("datadog_rum_replay_sample_rate"),
  trackUserInteractions: true,
  // setting `trackResources` to true causes issues with the rollbar CDN
  trackResources: false,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

export default class Log {
  static loggingInActive() {
    return process?.env?.NODE_ENV === "development" || isPreviewMode();
  }

  static debug(message, messageContext) {
    if (Log.loggingInActive()) return;

    datadogLogs.logger.debug(message, messageContext);
  }

  static info(message, messageContext) {
    if (Log.loggingInActive()) return;

    datadogLogs.logger.info(message, messageContext);
  }

  static warn(message, messageContext) {
    if (Log.loggingInActive()) return;

    datadogLogs.logger.warn(message, messageContext);
  }

  static error(message, messageContext) {
    if (Log.loggingInActive()) return;

    datadogLogs.logger.error(message, messageContext);
  }
}
